import axios from 'axios'
const ACTIVIDADES = "actividad", PARADA_PLANTA = "parada-planta"

const state = {
  listControlSIG: false,
  listParadas: false,
  user: null
};

const getters = {
  getListControlSIG: state => state.listControlSIG,
  getListParadas: state => state.listParadas,
  currentUser: state => state.user
};

const mutations = {
  setListControlSIG(state, item) {
    state.listControlSIG = item;
  },
  setListParadas(state, item) {
    state.listParadas = item;
  },
  clearUser(state) {
    state.isAuthenticated = false;
    state.user = null;
  }
};
const actions = {
  findControlSIG({ state, commit }, request) {
    console.log('request', request);
    return new Promise((resolve, reject) => {
      axios
        .get(`${ACTIVIDADES}/get-all`, {params: request})
        .then((response) => {
          resolve(response.data)
          commit('setListControlSIG', response.data.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err)
        })
    });
  },
  saveControlSIG({ state, commit }, request) {
    console.log('request', request);
    const body = {
      ...request,
      id017Categoria: {
        idParametro: request.id017Categoria
      },
      id016TipoRespuesta: {
        idParametro: request.id016TipoRespuesta 
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${ACTIVIDADES}/save`, body)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err.response)
        })
    });
  },
  deleteControlSIG({ state, commit }, request) {
    console.log('request', request);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ACTIVIDADES}/get-all`, {params: request})
        .then((response) => {
          resolve(response.data)
          commit('setListControlSIG', response.data.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err)
        })
    });
  },
  updateControlSIG({ state, commit }, request) {
    console.log('request', request);
    const body = {
      ...request,
      id017Categoria: {
        idParametro: request.id017Categoria
      },
      id016TipoRespuesta: {
        idParametro: request.id016TipoRespuesta 
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`${ACTIVIDADES}/update`, body)
        .then((response) => {
          resolve(response.data)
          commit('setListControlSIG', response.data.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err.response)
        })
    });
  },
  findParadaPlanta({ state, commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PARADA_PLANTA}/get-all`, {params: request})
        .then((response) => {
          resolve(response.data)
          commit('setListParadas', response.data.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err)
        })
    });
  },
  saveParadaPlanta({ state, commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${PARADA_PLANTA}/save`, request)
        .then((response) => {
          resolve(response.data)
          commit('setListParadas', response.data.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err.response)
        })
    });
  },
  updateParadaPlanta({ state, commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${PARADA_PLANTA}/update`, request)
        .then((response) => {
          resolve(response.data)
          commit('setListParadas', response.data.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err.response)
        })
    });
  },
  deleteParadaPlanta({ state, commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${PARADA_PLANTA}/get-all`, {params: request})
        .then((response) => {
          resolve(response.data)
          commit('setListParadas', response.data.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err)
        })
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};