<template>
    <h1>Cerrar</h1>
</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
import axios from 'axios';
import { api, files } from '@/utils/utilidades';
axios.defaults.baseURL = api;
const {
  mapGetters: usuarioMapGetter,
  mapActions: usuarioMapActions
} = createNamespacedHelpers('usuario')

export default {
  computed: {
    ...usuarioMapGetter([
      'isLoggedIn',
      'menu',
      'user',
      'roles',
      'imagenUser',
      'idUser'
    ]
    ),
  },
  created() {
    this.cerrarSesion()
  },
  mounted(){
    this.cerrarSesion()
  },
  methods: {
    ...usuarioMapActions([
      'login',
      'SETEAR_USER',
      'SETEAR_MENU',
      'SETEAR_ROLES',
      'SETEAR_ESTADOSXROLES',
      'SETEAR_ESTADOSSOLICITUDES',
      'SETEAR_IMAGENUSUARIO',
      'SETEAR_IDUSER'
    ]),
    cerrarSesion() {
      const fecha = new Date();
      const year = fecha.getFullYear();
      const month = String(fecha.getMonth() + 1).padStart(2, '0');
      const day = String(fecha.getDate()).padStart(2, '0');

      const horas = String(fecha.getHours()).padStart(2, '0');
      const minutos = String(fecha.getMinutes()).padStart(2, '0');
      const segundos = String(fecha.getSeconds()).padStart(2, '0');

      const horaActual = `${horas}:${minutos}:${segundos}`;
      const fechaActual = `${year}-${month}-${day}`;

      const log = {
        accion: "CERRAR_SESION",
        idUsuario: this.idUser,
        fecha: fechaActual,
        hora: horaActual,
      }
      axios
        .post('cerrar_sesion', log)
        .then((response) => {
        })
        .catch((err) => {
          console.log('err', err)
        });

      this.$session.destroy()
      this.SETEAR_USER(null);
      this.$router.replace('/login');
      this.SETEAR_MENU(null);
      this.SETEAR_ROLES(null);
      this.SETEAR_ESTADOSXROLES(null);
      this.SETEAR_ESTADOSSOLICITUDES(null);
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>