
'use strict'
import axios from 'axios'
import { api, files } from '../utils/utilidades'
axios.defaults.baseURL = api;
export default {
    namespaced: true,
    state: {
        listaClasesEquipo: [],
        listaObjetosTecnicos: [],
        objetoFijado: [],

    },
    getters: {
        listaClasesEquipo: state => state.listaClasesEquipo,
        listaObjetosTecnicos: state => state.listaObjetosTecnicos,
        objetoFijado: state => state.objetoFijado,

    },
    mutations: {
        SET_LISTACLASESEQUIPO(state, listaClasesEquipo) {
            state.listaClasesEquipo = listaClasesEquipo
        },
        SET_LISTAOBJETOSTECNICOS(state, listaObjetosTecnicos) {
            state.listaObjetosTecnicos = listaObjetosTecnicos
        },
        SET_OBJETOFIJADO(state, objetoFijado) {
            state.objetoFijado = objetoFijado
        },

    },
    actions: {
        async GET_LISTACLASESEQUIPO({ commit }) {
            await axios.get('clases_equipo').
                then(response => {
                    const items = response.data;
                    commit('SET_LISTACLASESEQUIPO', items)
                }).catch(error => {
                    console.log(error);
                    commit('SET_LISTACLASESEQUIPO', [])
                });
        },
        async GET_LISTAOBJETOSTECNICOS({ commit }, filtros) {
            await axios.get('objetos_tecnicos_filtrados', {
                params: {
                    idEstado: filtros[0],
                    idDepartamento: filtros[1],
                    idCc: filtros[2],
                    idTipo: filtros[3],
                    idUbicacion: filtros[4],
                    codigo: filtros[5],
                    nombre: filtros[6],
                }
            }).then(response => {
                const items = response.data;

                commit('SET_LISTAOBJETOSTECNICOS', items)
            });
        },
        SETERAR_OBJETO({ commit }, objeto){
            commit('SET_OBJETOFIJADO', objeto) 
        }

    },
    modules: {
    }
}

