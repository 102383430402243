<template>
    <v-dialog v-model="modalOpen" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn icon @click="openModal" v-on="on">
                <v-icon color="white">mdi-camera</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="amarilloCallao">
                <span class="text-h5">Cargar Imagen</span>
            </v-card-title>
            <v-card-text>
                <v-file-input v-model="selectedImage" accept="image/*" @change="uploadImage"></v-file-input>
                <template v-if="showImage">
                    <v-img :src="imageUrl" v-if="selectedImage != null"></v-img>
                </template>
            </v-card-text>
            <v-card-actions class="amarilloCallao">
                <v-spacer></v-spacer>
                <v-btn color="success" v-if="selectedImage != null" class="text-none" @click="guardar()">Guardar</v-btn>
                <v-btn color="error" class="text-none" @click="closeModal">Salir</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import axios from 'axios'
import { api, files } from '@/utils/utilidades';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
const {
    mapGetters: usuarioMapGetter,
    mapActions: usuarioMapActions
} = createNamespacedHelpers('usuario')
export default {
    data() {
        return {
            modalOpen: false,
            selectedImage: null,
            showImage: false,
            imageUrl: null
        };
    },
    created() {
        if (this.$session.exists()) {
            this.SETEAR_USER(this.$session.get('userData'));
        } else {
            this.$router.push('/login');
        }
    },
    computed: {
        ...usuarioMapGetter([
            'isLoggedIn',
            'roles',
            'user'
        ]
        ),
    },
    methods: {
        ...usuarioMapActions([
            'SETEAR_USER',
            'SETEAR_MENU',
            'SETEAR_ROLES',
            'SETEAR_IMAGENUSUARIO'
        ]
        ),
        openModal() {
            this.modalOpen = true;
        },
        closeModal() {
            this.modalOpen = false;
            this.selectedImage = null;
            this.showImage = false;
            this.imageUrl = null;
        },
        uploadImage() {
            if (this.selectedImage != null) {
                this.showImage = true;
                this.imageUrl = URL.createObjectURL(this.selectedImage);
            }
            // Resto del código para procesar la imagen cargada
        },
        guardar() {
            let listaDatosAdjunto = [];
            const formData = new FormData();
            console.log(this.user);

            formData.append("files", this.selectedImage);
            const Archivo = {
                idEntidadPrincipal: this.user.idPersona.id,
                tipoEntidadPrincipal: { idTipoEntidad: 6 },
                descripcion: 'Actualizacion de imagen de usuario',
                info1: 'UPDATE_FOTO_USUARIO'
            };
            listaDatosAdjunto.push(Archivo);


            formData.append("datosAdjunto", JSON.stringify(listaDatosAdjunto));

            axios.post("files/upload", formData).then(response => {
                console.log("Los archivos fueron cargados correctamente");
                this.$session.set('imagenUsuario', api + 'imagen/' + response.data.adjuntosList[0].id);
                this.SETEAR_IMAGENUSUARIO(api + 'imagen/' + response.data.adjuntosList[0].id);
                this.closeModal();
            })
                .catch(error => {
                    console.log("Ocurrio un error al guardar los archivos", error);
                    this.closeModal()
                })
        }
    }
};
</script>
