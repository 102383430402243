import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  transitions: true,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#553725',
        secondary: '#7c5b47',
        accent: '#82B1FF',
        error: '#dc3545',
        info: '#2196F3',
        success: '#28a745',
        warning: '#FFC107',
        primaryCallao: '#553725',
        secundaryCallao: '#7c5b47',
        amarilloCallao: '#f8b051',
        grisClaro: '#F9FAFB',
        tituloTabla:'#dee2e6',
        mensajeEnlace:'#6c757d',
        black: '#000000',
      }
    },
  },
});
