
'use strict'
import axios from 'axios'
import { api, files } from '../utils/utilidades'
axios.defaults.baseURL = api;
export default {
    namespaced: true,
    state: {
        solicitudesTrabajo: [],
        estadosSolicitud: [],
        numeroSolicitud: '',
        listaAreas: [],
        nuevaSolicitud: false,
        listaSolicitudes: [],
        listaEstadosSolicitud: [],
    },
    getters: {
        solicitudesTrabajo: state => state.solicitudesTrabajo,
        estadosSolicitud: state => state.estadosSolicitud,
        numeroSolicitud: state => state.numeroSolicitud,
        listaAreas: state => state.listaAreas,
        nuevaSolicitud: state => state.nuevaSolicitud,
        listaSolicitudes: state => state.listaSolicitudes,
        listaEstadosSolicitud: state => state.listaEstadosSolicitud,

    },
    mutations: {
        SET_SOLICITUDESTRABAJO(state, solicitudesTrabajo) {
            state.solicitudesTrabajo = solicitudesTrabajo
        },
        SET_ESTADOSSOLICITUD(state, estadosSolicitud) {
            state.estadosSolicitud = estadosSolicitud
        },
        SET_NUMEROSOLICITUD(state, numeroSolicitud) {
            state.numeroSolicitud = numeroSolicitud
        },
        SET_LISTAAREAS(state, listaAreas) {
            state.listaAreas = listaAreas
        },
        SET_NUEVASOLICITUD(state, nuevaSolicitud) {
            state.nuevaSolicitud = nuevaSolicitud
        },
        SET_LISTASOLICITUDES(state, listaSolicitudes) {
            state.listaSolicitudes = listaSolicitudes
        },
        SET_LISTAESTADOS(state, listaEstadosSolicitud) {
            state.listaEstadosSolicitud = listaEstadosSolicitud
        },
    },
    actions: {
        async GET_SOLICITUDESTRABAJO({ commit }, filtros) {
            await axios.get('solicitudes_ot', {
                params: {
                    idEstado: filtros[0],
                    idPrioridad: filtros[1],
                    fechaInicio: filtros[2],
                    fechaFin: filtros[3],
                    idDepartamento: filtros[4],
                    paradaEquipo: filtros[5],
                    nroSolicitud: filtros[6],
                    equipo: filtros[7],
                    idSolicitante: filtros[8]
                }
            }).then(response => {
                const items = response.data;
                let solicitudes = [];
                for (const item of items) {
                    let solicitudOrdenTrabajo = {
                        id: item.id,
                        sel: false, //(item.id022Estado.idParametro == 121) ? true : false,
                        numero: item.numero,
                        descripcion: item.descripcion,
                        detalleAdicional: item.detalleAdicional,
                        objetoTecnico: item.objetoTecnico,
                        flagParada: (item.flagParada == 1) ? 'Si' : 'No',
                        flagDetieneEmbarque: (item.flagDetieneEmbarque == 1) ? 'Si' : 'No',
                        id021Prioridad: item.id021Prioridad,
                        departamento: item.departamento,
                        areaSolicitante: item.areaSolicitante,
                        usuarioSolicitante: item.usuarioSolicitante,
                        fechaIncidente: item.fechaIncidente,
                        fechaRequeridaAtencion: item.fechaRequeridaAtencion,
                        idOrdenTrabajo: item.idOrdenTrabajo,
                        id022Estado: item.id022Estado,
                        infoPadre: item.infoPadre,
                        trazabilidad: item.trazabilidad,
                    };
                    solicitudes.push(solicitudOrdenTrabajo);
                }

                commit('SET_SOLICITUDESTRABAJO', solicitudes)
            });
        },
        GET_SOLICITUDTRABAJO({ commit }, solicitudes) {
            let listaSolicitudes = [];
            for (const item of solicitudes) {
                let solicitudOrdenTrabajo = {
                    id: item.id,
                    numero: item.numero,
                    descripcion: item.descripcion,
                    detalleAdicional: item.detalleAdicional,
                    objetoTecnico: item.objetoTecnico,
                    flagParada: (item.flagParada == 1) ? 'Si' : 'No',
                    flagDetieneEmbarque: (item.flagDetieneEmbarque == 1) ? 'Si' : 'No',
                    id021Prioridad: item.id021Prioridad,
                    departamento: item.departamento,
                    areaSolicitante: item.areaSolicitante,
                    usuarioSolicitante: item.usuarioSolicitante,
                    fechaIncidente: item.fechaIncidente,
                    fechaRequeridaAtencion: item.fechaRequeridaAtencion,
                    idOrdenTrabajo: item.idOrdenTrabajo,
                    id022Estado: item.id022Estado,
                    trazabilidad: item.trazabilidad,
                };
                listaSolicitudes.push(solicitudOrdenTrabajo);
            }
            commit('SET_LISTASOLICITUDES', listaSolicitudes)
        },
        async GET_ESTADOSSOLICITUD({ commit }) {
            axios.get('estado_solicitud').then(response => {
                const items = response.data;
                commit('SET_ESTADOSSOLICITUD', items)
            });
        },
        async GET_NUMEROSOLICITUD({ commit }) {
            axios.get('numero_solicitud').then(response => {
                const items = String(response.data).padStart(8, '0');
                commit('SET_NUMEROSOLICITUD', items)
            });
        },
        ASIGNAR_NUMEROSOLICITUD({ commit }, numero) {
            commit('SET_NUMEROSOLICITUD', numero)

        },
        async GET_LISTAAREAS({ commit }) {
            axios.get('areas').then(response => {
                const items = response.data;
                commit('SET_LISTAAREAS', items)
            });
        },
        SETEAR_NUEVASOLICITUD({ commit }, item) {
            commit('SET_NUEVASOLICITUD', item)
        },
        async GET_LISTAESTADOS({ commit }) {
            await axios.get('obtener_parametro_order_id', {
                params: {
                    tipo: 22,
                    estado: 1
                }
            }).then(response => {
                const items = response.data;
                commit('SET_LISTAESTADOS', items)
            }).catch(error => {
                console.log(error);
                commit('SET_LISTAESTADOS', []);
            });
        },
    },
    modules: {
    }
}

