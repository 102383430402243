const state = {
  isAuthenticated: false,
  user: null
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  currentUser: state => state.user
};

const mutations = {
  setUser(state, user) {
    state.isAuthenticated = true;
    state.user = user;
  },
  clearUser(state) {
    state.isAuthenticated = false;
    state.user = null;
  }
};

const actions = {
  setUser(context, user) {
    context.commit("setUser", user);
  },
  clearUser(context) {
    context.commit("clearUser");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};