<template>

</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex'

const {
  mapGetters: usuarioMapGetter,
  mapActions: usuarioMapActions
} = createNamespacedHelpers('usuario')

export default {
  computed: {
    ...usuarioMapGetter([
      'isLoggedIn',
    ]
    ),
  },
  created() {
  },
  methods: {
    ...usuarioMapActions([
      'SETEAR_USER',
      'SETEAR_MENU',
      'SETEAR_ROLES'
    ]
    ),
    cerrarSesion() {
      this.$session.destroy();
      this.$router.push('/login');
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>