'use strict'
import axios from 'axios'
import { api, files } from '../utils/utilidades'
axios.defaults.baseURL = api;
export default {
    namespaced: true,
    state: {
        listMedidores: [],
        listMedidoresFiltradas: [],
        medidor: null,
        itemsUnd: [],
        itemsCambioValor: [],
        itemsRequerido: [{
            idParametro: 1,
            descripcion: 'Si'
        },
        {
            idParametro: 0,
            descripcion: 'No'
        }],
        itemsEstados: [{
            idParametro: 1,
            descripcion: 'ACTIVO'
        },
        {
            idParametro: 2,
            descripcion: 'INACTIVO'
        }],
        listLecturas: []

    },
    getters: {
        listMedidores: state => state.listMedidores,
        listMedidoresFiltradas: state => state.listMedidoresFiltradas,
        medidor: state => state.medidor,
        itemsUnd: state => state.itemsUnd,
        itemsCambioValor: state => state.itemsCambioValor,
        itemsRequerido: state => state.itemsRequerido,
        itemsEstados: state => state.itemsEstados,
        listLecturas: state => state.listLecturas,

    },
    mutations: {
        SET_LISTMEDIDORES(state, listMedidores) {
            state.listMedidores = listMedidores
        },
        SET_LISTMEDIDORESFILTRADAS(state, listMedidoresFiltradas) {
            state.listMedidoresFiltradas = listMedidoresFiltradas
        },
        SET_MEDIDOR(state, medidor) {
            state.medidor = medidor
        },
        SET_ITEMSUND(state, itemsUnd) {
            state.itemsUnd = itemsUnd
        },
        SET_ITEMSCAMBIOVALOR(state, itemsCambioValor) {
            state.itemsCambioValor = itemsCambioValor
        },
        SET_ITEMSREQUERIDO(state, itemsRequerido) {
            state.itemsRequerido = itemsRequerido
        },
        SET_ITEMSESTADOS(state, itemsEstados) {
            state.itemsEstados = itemsEstados
        },
        SET_LISTALECTURAS(state, listLecturas) {
            state.listLecturas = listLecturas
        }
    },
    actions: {
        async GET_LISTAMEDIDORES({ commit }) {
            await axios.get("lista_medidores").then(response => {
                commit('SET_LISTMEDIDORES', response.data)
            }).catch(error => {
                commit('SET_LISTMEDIDORES', [])
            })
        },
        async GET_LISTAMEDIDORESFILTRADAS({ commit }, value) {
            await axios.get('lista_medidores_filtrada', {
                params: {
                    codigo: value[0],
                    nombre: value[1],
                    undMedida: value[2],
                    cambValor: value[3],
                    equipo: value[4],
                    requerido: value[5],
                    estado: value[6],
                }
            }).then((response) => {
                const medidores = response.data;
                commit('SET_LISTMEDIDORESFILTRADAS', medidores)
            }).catch(error => {
                console.error(error)
                commit('SET_LISTMEDIDORESFILTRADAS', [])
            })
        },
        SETEAR_MEDIDOR({ commit }, medidor) {
            commit('SET_MEDIDOR', medidor)
        },
        async GET_ITEMSUNDS({ commit }) {
            await axios.get("unds_medidor").then(response => {
                commit('SET_ITEMSUND', response.data)
            }).catch(error => {
                commit('SET_ITEMSUND', [])
            })
        },
        async GET_ITEMSCAMBIOVALOR({ commit }) {
            await axios.get("camb_valor_medidor").then(response => {
                commit('SET_ITEMSCAMBIOVALOR', response.data)
            }).catch(error => {
                commit('SET_ITEMSCAMBIOVALOR', [])
            })
        },
        async GET_LISTALECTURASFILTRADAS({ commit }, value) {
            await axios.get('lista_lecturas_filtrada', {
                params: {
                    equipo: value[0],
                    medidor: value[1],
                    estado: value[2],
                    inicio: value[3],
                    fin: value[4]
                }
            }).then((response) => {
                const lecturas = response.data;
                commit('SET_LISTALECTURAS', lecturas)
            }).catch(error => {
                console.error(error)
                commit('SET_LISTALECTURAS', [])
            })
        },
    },
    modules: {
    }
}