'use strict'
import axios from 'axios'
import { api, files } from '../utils/utilidades'
axios.defaults.baseURL = api;
export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        persona: [],
        permisos: [],
        user: null,
        menu: [],
        roles: null,
        estadosxRol: [],
        estadosSolicitudes: [],
        imagenUser: null,
        idUser:null,

    },
    getters: {
        persona: state => state.persona,
        permisos: state => state.permisos,
        isLoggedIn: state => state.isLoggedIn,
        user: state => state.user,
        imagenUser: state => state.imagenUser,
        menu: state => state.menu,
        roles: state => state.roles,
        estadosxRol: state => state.estadosxRol,
        estadosSolicitudes: state => state.estadosSolicitudes,
        idUser: state => state.idUser,
    },
    mutations: {
        SET_PERSONA(state, persona) {
            state.persona = persona
        },
        SET_PERMISOS(state, permisos) {
            state.permisos = permisos
        },
        setUser(state, user) {
            state.user = user
        },
        SET_IMAGENUSER(state, imagenUser) {
            state.imagenUser = imagenUser
        },
        clearUser(state) {
            state.user = null
        },
        SET_lOGIN(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn
        },
        SET_MENU(state, menu) {
            state.menu = menu
        },
        SET_ROLES(state, roles) {
            state.roles = roles
        },
        SET_ESTADOSXROLES(state, estadosxRol) {
            state.estadosxRol = estadosxRol
        },
        SET_ESTADOSSOLICITUDES(state, estadosSolicitudes) {
            state.estadosSolicitudes = estadosSolicitudes
        },
        SET_IDUSER(state, idUser) {
            state.idUser = idUser
        },
    },
    actions: {
        login({ commit }, isLoggedIn) {
            commit('SET_lOGIN', isLoggedIn)
        },
        logout({ commit }) {
            commit('clearUser')
        },
        SETEAR_USER({ commit }, user) {
            commit('setUser', user)
        },
        async iniciarSesion({ commit }, value) {
            // Encriptamos la clave con bcrypt antes de enviarla al backend

        },
        SETEAR_MENU({ commit }, menu) {
            commit('SET_MENU', menu)
        },
        SETEAR_ROLES({ commit }, roles) {
            commit('SET_ROLES', roles)
        },
        SETEAR_ESTADOSXROLES({ commit }, estados) {
            commit('SET_ESTADOSXROLES', estados)
        },
        SETEAR_ESTADOSSOLICITUDES({ commit }, estados) {
            commit('SET_ESTADOSSOLICITUDES', estados)
        },
        SETEAR_IMAGENUSUARIO({ commit }, imagen){
            commit('SET_IMAGENUSER', imagen)
        },
        SETEAR_IDUSER({ commit }, id){
            commit('SET_IDUSER', id)
        }
    },
    modules: {
    }
}