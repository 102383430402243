import axios from 'axios'
const state = {
  listOrders: [],
  listOrdersForExecute: [],
  listOrdersForPlanning: [],
  ordenFull: {},
  orden: {},
  operaciones: [],
  servicioOperaciones: [],
  subOperaciones: [],
  materialesOperacion: [],
  controlSig: [],
  lecturasMedidor: [],
}

const getters = {
  listOrders: (state) => state.listOrders,
  listOrdersForExecute: (state) => state.listOrdersForExecute,
  listOrdersForPlanning: (state) => state.listOrdersForPlanning,
  ordenFull: (state) => {
    return state.ordenFull
  },
  orden: (state) => {
    return state.orden
  },
  operaciones: (state) => {
    return state.operaciones
  }
}

const mutations = {
  addToCart(state, item) {
    state.items.push(item)
  },
  setOrdenFull(state, value) {
    state.orden = value.orden;
    state.operaciones = value.operaciones;
    state.servicioOperaciones = value.servicioOperaciones;
    state.subOperaciones = value.subOperaciones;
    state.materialesOperacion = value.materialesOperacion;
    state.controlSig = value.controlSig;
    state.lecturasMedidor = value.lecturasMedidor;

    state.ordenFull = value;
  },
  setListOrders(state, value) {
    state.listOrders = value
  },
  setListOrdersForExecute(state, value) {
    state.listOrdersForExecute = value
  },
  setOrden(state, value) {
    state.ordenFull.orden = value
    state.orden = value
  }
}

const actions = {
  get({ dispatch }, form) {
    console.log('Hola Vuex', form)
  },
  addToCart(context, item) {
    context.commit('addToCart', item)
  },
  getOrderById({ state, commit }, idOrder) {
    return new Promise((resolve, reject) => {
      axios
        .get('obtenerOrdenTrabajo', {
          params: {
            idOrden: idOrder,
          },
        })
        .then((res) => {
          if (res) {
            commit('setOrdenFull', res.data)
            resolve(res)
          } else {
            console.log('Mostrando reject', res)
            reject(res)
          }
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },
  getAllOrders({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .get('listaOrdenes')
        .then((response) => {
          commit(
            'setListOrdersForExecute',
            response.data.filter((card) => card.idEstado === 54),
          )
          commit('setListOrders', response.data)
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },
  setTracking({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('guardarOrdenTrabajoTrazabilidad', object)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },
  setUpdateOrderWork({dispatch, state, commit }, arr) {
    return new Promise((resolve, reject) => {
      axios
        .post('actualizarOrdenTrabajo?idUsuario='+arr[1], arr[0])
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setUpdateOrderWorkStatus({dispatch, state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('actualizarOrdenTrabajoEstado', object)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setUpdateOrderOperation({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('actualizarOrdenTrabajoOperacion', object)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setUpdateRecursos({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('actualiizarOrdenTrabajoRecurso', object)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setAddRecursos({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('guardarOrdenTrabajoRecurso', object)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setSaveEmpleado({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('actualiizarOrdenTrabajoRecursoTrabajador', object.body, {
          params: {
            idRecurso: object.idRecurso
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setUpdateEmpleado({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('updateOrdenTrabajoRecursoTrabajador', object)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setUpdateSubOperacion({ state, commit }, object) {
    console.log(object);
    return new Promise((resolve, reject) => {
      axios
        .post('actualizarListaSubOperacion', object.body, {
          params: {
            nroOp: object.numeroOperacion,
            idOrden: object.idOrden
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  saveFiles({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('files/upload', object)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  updateFiles({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .post('files/update-archivo', object)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  deleteFIle({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .delete('files/delete', {
          params: {
            idArchivo: object.idArchivo
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err)
        })
    });
  },
  getListFiles({ state, commit }, object) {
    //http://localhost:8097/files/obtenerFiles?idEntidadPrincipal=18&tipoEntidadPrincipal=1
    return new Promise((resolve, reject) => {
      axios
        .get('files/obtenerFiles', {
          params: {
            ...object
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setUpdateSolicitudOrden({ state, commit }, object) {
    return new Promise((resolve, reject) => {
      axios
        .get('actualizarSolicitudOrden', {
          params: {
            idSolicitud: object.idSolicitud, idEstado: object.idEstado, idOrden: object.idOrden
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  addMateriales({ state, commit }, object) {
    //http://localhost:8097/files/obtenerFiles?idEntidadPrincipal=18&tipoEntidadPrincipal=1
    return new Promise((resolve, reject) => {
      axios
        .post('agregarOrdenTrabajoOperacionListaMaterial', object.body, {
          params: {
            nroOp: object.numeroOperacion,
            idOrden: object.idOrden
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  updateMateriales({ state, commit }, object) {
    //http://localhost:8097/files/obtenerFiles?idEntidadPrincipal=18&tipoEntidadPrincipal=1
    return new Promise((resolve, reject) => {
      axios
        .post('actualizarOrdenTrabajoOperacionListaMaterial', object.body, {
          params: {
            nroOp: object.numeroOperacion,
            idOrden: object.idOrden
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  updateServicios({ state, commit }, object) {
    //http://localhost:8097/files/obtenerFiles?idEntidadPrincipal=18&tipoEntidadPrincipal=1
    return new Promise((resolve, reject) => {
      axios
        .post('actualizarOrdenTrabajoOperacionListaServicio', object.body, {
          params: {
            nroOp: object.numeroOperacion,
            idOrden: object.idOrden
          }
        }, object.body)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  addServicios({ state, commit }, object) {
    //http://localhost:8097/files/obtenerFiles?idEntidadPrincipal=18&tipoEntidadPrincipal=1
    return new Promise((resolve, reject) => {
      axios
        .post('agregarOrdenTrabajoOperacionListaServicio', object.body, {
          params: {
            nroOp: object.numeroOperacion,
            idOrden: object.idOrden
          }
        }, object.body)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    });
  },
  setUpdateOrderWorkParams({ state, commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .post('actualizarDatosOrdenTrabajo', request)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err);
          reject(err)
        })
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
