import Vue from 'vue'
import Vuex from 'vuex'
import orden from './orden'
import auth from './auth'
import params from './params'
import ordenstore from './ordenstore'
import solicitudes from './solicitudes'
import parametrosStore from './parametrosStore'
import objetosTecnicos from './objetosTecnicos'
import usuario from './usuario'
import mantenimiento from './mantenimiento'
import standarJob from './standarJob'
import medidores from './medidores'
import preventivo from './preventivo'
import VueSession from 'vue-session'

Vue.use(Vuex)
Vue.use(VueSession)

export default new Vuex.Store({
  modules: {
    orden,
    auth,
    params,
    ordenstore,
    solicitudes,
    parametrosStore,
    objetosTecnicos,
    usuario,
    mantenimiento,
    standarJob,
    medidores,
    preventivo
  }
})
