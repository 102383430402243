import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CerrarSesion from '../views/CerrarSesion.vue'
import NotFoundView from '../views/NotFoundView.vue'
import store from '../store/index'; 

Vue.use(VueRouter)

// Configura el temporizador y los eventos de inactividad
let inactivityTimer
const inactivityTimeout = 30 * 60 * 1000 // 10 minutos en milisegundos

function resetInactivityTimer() {
  if (inactivityTimer) {
    clearTimeout(inactivityTimer);
  }
  inactivityTimer = setTimeout(() => {
    if (router.currentRoute.path !== '/login') {
      router.push('/cerrar'); // Utiliza router.replace en lugar de router.push
    }
  }, inactivityTimeout);
}

// Escucha los eventos de interacción del usuario
document.addEventListener('mousemove', resetInactivityTimer)
document.addEventListener('keydown', resetInactivityTimer)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: ""
    },
  },
  {
    path: '/cerrar',
    name: 'cerrar',
    component: CerrarSesion,
    meta: {
      title: ""
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: ""
    }
  },
  {
    path: '/ordenes',
    name: 'ordenes',
    component: () => import('../views/OrdenesTrabajo.vue'),
    meta: {
      title: "TABLERO DE CONTROL"
    },
    props: true,
  },
  {
    path: '/bandejaOrdenes',
    name: 'Bandeja Ordenes',
    component: () => import('../views/BandejaOrdenes.vue'),
    meta: {
      title: "GESTIÓN DE ORDENES"
    }
  },
  {
    path: '/solicitudes',
    name: 'solicitudes',
    component: () => import('../views/SolicitudesOrdenesTrabajo.vue'),
    meta: {
      title: "SOLICITUDES"
    },
    props: true,
  },
  {
    path: '/mantenimientoObjetosTecnicos',
    name: 'Mantenimiento de Objetos Tecnicos',
    component: () => import('../views/MantenimientoObjetoTecnico.vue'),
    meta: {
      title: "MANTENIMIENTO"
    }
  },
  {
    path: '/mantenimientoControlSig',
    name: 'Mantenimiento de Control SIG',
    component: () => import('../views/MantenimientoControlSig.vue'),
    meta: {
      title: "Control SIG"
    }
  },
  {
    path: '/mantenimientoParadaPlanta',
    name: 'Mantenimiento de Parada de PLanta',
    component: () => import('../views/MantenimientoParadaPlanta.vue'),
    meta: {
      title: "Parada Planta"
    }
  },
  {
    path: '/mantenimientoSubOperaciones',
    name: 'Mantenimiento de SubOperaciones',
    component: () => import('../views/MantenimientoSubOperaciones.vue'),
    meta: {
      title: "SubOperaciones"
    }
  },
  {
    path: '/jerarquiaObjetosTecnicos',
    name: 'Jerarquia Equipos',
    component: () => import('../views/Jerarquia.vue'),
    meta: {
      title: "JERARQUÍA"
    }
  },
  {
    path: '/actividadesEstandares',
    name: 'Actividades Estandares',
    component: () => import('../views/ActividadesEstandares.vue'),
    meta: {
      title: "STANDARDS JOB"
    }
  },
  {
    path: '/consultaMedidores',
    name: 'Mantenimiento Medidores',
    component: () => import('../views/MantenimientoMedidor.vue'),
    meta: {
      title: "MANTENIMIENTO CONTADORES"
    }
  },
  {
    path: '/consultaPreventivo',
    name: 'Mantenimiento Preventivo',
    component: () => import('../views/MantenimientoPreventivo.vue'),
    meta: {
      title: "PLAN DE MANTENIMIENTO"
    }
  },
  {
    path: '/consultaOrdenesTrabajo',
    name: 'Consulta Ordenes',
    component: () => import('../views/BandejaConsultaOrdenes.vue'),
    meta: {
      title: "CONSULTA ORDENES DE TRABAJO"
    }
  },
  {
    path: '/consultaRegistroLecturas',
    name: 'Registro Lecturas',
    component: () => import('../views/BandejaRegistroLecturas.vue'),
    meta: {
      title: "LECTURA DE CONTADORES"
    }
  },
  {
    path: '/simulacionOrdenes',
    name: 'Generacion Ordenes',
    component: () => import('../views/GeneracionOrdenes.vue'),
    meta: {
      title: "PROYECCIÓN DE ORDENES"
    }
  },
  {
    path: '/mantenimientoMateriales',
    name: 'Actualizacion Materiales',
    component: () => import('../views/MantenimientoMateriales.vue'),
    meta: {
      title: "ACTUALIZACIÓN MATERIALES"
    }
  },
  {
    path: '/kpis',
    name: 'Programacion Mantenimiento',
    component: () => import('../views/KPI_programacionMantenimiento.vue'),
    meta: {
      title: "CENTRO DE KPIS"
    }
  },
  {
    path: '/mantenimientoUsuarios',
    name: 'Mantenimiento Usuarios',
    component: () => import('../views/BandejaUsuarios.vue'),
    meta: {
      title: "REGISTRO DE USUARIOS"
    }
  },
  {
    path: '/mantenimientoEmpleados',
    name: 'Mantenimiento Empleados',
    component: () => import('../views/BandejaEmpleados.vue'),
    meta: {
      title: "REGISTRO DE EMPLEADOS"
    }
  },
  {
    path: '/mantenimientoPerfiles',
    name: 'Mantenimiento Perfiles',
    component: () => import('../views/BandejaPerfiles.vue'),
    meta: {
      title: "REGISTRO DE PERFILES"
    }
  },
  {
    path: '/*',
    name: 'notFound',
    component: NotFoundView
  },

]

const router = new VueRouter({
  routes,
  created() {
    resetInactivityTimer()
  },
})

export default router
