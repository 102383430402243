<template>
    <section>
        <v-dialog v-model="dialog" persistent max-width="300px" @keydown.esc="salir()">
            <v-card>
                <v-card-title class="amarilloCallao">
                    <span class="text-h5">Cambio de Clave</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="margin-top:15px">
                    <v-container v-if="!correcto">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="claveActual" label="Clave Actual*" outlined dense
                                    @input="changeClave" :error.sync="claveError" :hide-details="true" color="primaryCallao"
                                    type="password" prepend-icon="mdi-lock">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="claveNueva" label="Clave Nueva*" outlined dense color="primaryCallao"
                                    @input="changeNuevo" :error.sync="nuevaError" :hide-details="true" type="password"
                                    prepend-icon="mdi-lock-reset">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="claveNuevaRep" label="Confirmar clave*" outlined dense
                                    @input="changeNuevoRep" :error.sync="nuevaRepError" :hide-details="true"
                                    color="primaryCallao" type="password" prepend-icon="mdi-lock-check">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="error">
                                <p class="error-text">{{ mensajeError }}</p>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-else style=" display: flex; flex-direction: column; align-items: center;">
                        <p style="font-size:large ; text-align:center"> La clave ha sido cambiada satisfactoriamente.
                        </p>
                        <v-img src="@/assets/comprobado.png" style="margin-top:10px; margin-bottom:10px "
                            width="40px"></v-img>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="amarilloCallao">
                    <v-spacer></v-spacer>
                    <v-btn v-if="!correcto" color="success" class="text-none" @click="guardar()">
                        Guardar
                    </v-btn>
                    <v-btn color="error" class="text-none" @click="salir()">
                        Salir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </section>
</template>
<script>
import axios from 'axios'
import { api, files } from '@/utils/utilidades'
axios.defaults.baseURL = api;
import { mapActions, createNamespacedHelpers } from 'vuex';
axios.defaults.baseURL = api;

const {
    mapGetters: usuarioMapGetter,
    mapActions: usuarioMapActions
} = createNamespacedHelpers('usuario')

export default {
    computed: {
        ...usuarioMapGetter([
            'idUser',
        ]
        ),
    },
    props: ['value'],
    watch: {
        value: function (newValue) {
            this.dialog = newValue;
        },
        dialog(newValue) {
            if (!newValue) {
                this.$emit('input', newValue);
            }
        },
    },
    data() {
        return {
            dialog: this.value,
            claveActual: '',
            claveNueva: '',
            claveNuevaRep: '',
            claveError: false,
            nuevaError: false,
            nuevaRepError: false,
            mensajeError: '',
            error: false,
            correcto: false,
        }
    },
    created() {
    },
    methods: {
        salir() {
            this.dialog = false;
            this.error = false;
            this.correcto = false;
            this.mensajeError = '';
            this.claveActual = '';
            this.claveNueva = '';
            this.claveNuevaRep = '';
            this.claveError = false;
            this.nuevaError = false;
            this.nuevaRepError = false;
        },
        changeClave() {
            if (this.claveActual == '' || this.claveActual == null) {
                this.claveError = true;
            } else {
                this.claveError = false;
            }
        },
        changeNuevo() {
            if (this.claveNueva == '' || this.claveNueva == null) {
                this.nuevaError = true;
            } else {
                this.nuevaError = false;
            }
        },
        changeNuevoRep() {
            if (this.claveNuevaRep == '' || this.claveNuevaRep == null) {
                this.nuevaRepError = true;
            } else {
                this.nuevaRepError = false;
            }
        },
        async guardar() {
            this.changeClave();
            this.changeNuevo();
            this.changeNuevoRep();
            if (this.claveError || this.nuevaError || this.nuevaRepError) {
                this.error = true;
                this.mensajeError = 'Debe de completar todos los campos obligatorios (*).'

            } else {
                if (this.claveNueva === this.claveNuevaRep) {
                    if (this.claveNueva.length < 6) {
                        this.error = true;
                        this.nuevaRepError = true;
                        this.nuevaError = true;
                        this.mensajeError = 'La clave debe tener un mínimo de 6 caracteres';
                    } else {
                        axios.get('resetear_clave', {
                            params: {
                                id_user: this.idUser,
                                clave_actual: this.claveActual,
                                clave_nueva: this.claveNueva,
                            }
                        }).then(response => {
                            const respuesta = response.data;
                            if (respuesta.cambio) {
                                this.correcto = true;
                            } else {
                                this.error = true;
                                this.mensajeError = respuesta.mensaje;
                            }
                        }).catch(error => {
                            console.log(error);
                        });
                    }
                } else {
                    this.error = true;
                    this.nuevaRepError = true;
                    this.nuevaError = true;
                    this.mensajeError = 'La confirmacion de clave no coincide con la clave nueva.'
                }
            }
        }
    },

};
</script>

<style scoped>
.col {
    padding-top: 0px;
    padding-bottom: 0px;
}

.col-12 {
    padding-bottom: 0px;
    padding-top: 0px;
}

.row+.row {
    margin-top: 0px;
}

.row {
    margin: -12px 0px;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 15px;
}

.error-text {
    color: red;
    font-weight: bold;
    text-align: center;
}
</style>