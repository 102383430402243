import axios from 'axios'

const state = {
  listParamsTipo: [],
  listParams: [],
  medidor: null,
  listRecursos: null,
  listEmpleados: null,
  listMateriales: null,
  listServicios: null,
}

const getters = {
  getListParamsTipo: (state) => state.listParamsTipo,
  getListParams: (state) => state.listParams,
  getListRecursos: (state) => state.listRecursos,
  getListEmpleados: (state) => state.listEmpleados,
  getMedidor: (state) => {
    return state.medidor
  },
  getListMateriales: (state) => state.listMateriales,
  getListServicios: (state) => state.listServicios,
}
const mutations = {
  setMedidor(state, values) {
    state.medidor = values
  },
  setListRecursos(state, values) {
    state.listRecursos = values
  },
  setListEmpleados(state, values) {
    state.listEmpleados = values
  },
  setListMateriales(state, values) {
    state.listMateriales = values
  },
  setListServicios(state, values) {
    state.listServicios = values
  }
}

const actions = {
  getParamsMedidor({ getters, state, commit }, object) {
    console.log('Medidor ', getters.getMedidor)
    if (getters.getMedidor) return

    console.log('Medidor 1', state.getMedidor)
    return new Promise((resolve, reject) => {
      axios
        .get('parametros', {
          params: {
            idParametro: 19,
            idEstado: 1,
          },
        })
        .then((response) => {
          commit('setMedidor', response.data)
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },
  async getListRecursos({ getters, state, commit }, object) {
    if (getters.getListRecursos) return
    return new Promise((resolve, reject) => {
      axios
        .get('recursos')
        .then((response) => {
          commit('setListRecursos', response.data)
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },
  getEmpleados({ getters, state, commit }, object) {
    if (getters.getListEmpleados) return
    return new Promise((resolve, reject) => {
      axios
        .get('Empleados')
        .then((response) => {
          commit('setListEmpleados', response.data)
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },
  /*getEmpresas({ getters, state, commit }, object) {
    if (getters.getListEmpleados) return
    return new Promise((resolve, reject) => {
      axios
        .get('Empresas')
        .then((response) => {
          commit('setListEmpleados', response.data)
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },*/
  setTrazabilidad({ getters, state, commit }, object) {
    
    console.log("mostrar set Tracking");

    let request = {
      ...object,
      fechaRegistro: new Date()
    }
    if(request.referencia == null){
      request.referencia = '';
    }
    console.log("Mostrar objeto request setTrazabilidad", request);
    return new Promise((resolve, reject) => {
      axios
        .post('guardarOrdenTrabajoTrazabilidad', request)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },
  async getListaMateriales({ getters, state, commit }, material) {
    if (getters.getListMateriales ) return
    await axios.get('materiales').then(response => {
        commit('setListMateriales', response.data)
    }).catch(error => {
        commit('setListMateriales',  [])
    });
  },
  async getListaServicios({ getters, state, commit }, servicio) {
    if (getters.getListServicios ) return
    await axios.get('servicios', {
      params: {}
    }).then(response => {
      const items = response.data;
      commit('setListServicios', items);
    }).catch(error => {
      commit('setListServicios', []);
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
