<template>
    <div>
        <h1>Página no encontrada</h1>
        <p>La página que buscas no existe.</p>
    </div>
</template>
  
<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
const {
    mapGetters: usuarioMapGetter,
    mapActions: usuarioMapActions
} = createNamespacedHelpers('usuario')

export default {
    components: {

    },
    computed: {
        ...usuarioMapGetter([
            'isLoggedIn',
        ]
        ),
    },
    created() {
    },
    data: () => ({


    }),
    methods: {
        ...usuarioMapActions([
            'SETEAR_USER',
            'SETEAR_MENU'
        ]
        ),
    },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>