'use strict'
import axios from 'axios'
import { api, files } from '../utils/utilidades'
axios.defaults.baseURL = api;
export default {
    namespaced: true,
    state: {
        listPreventivo: [],
        listPreventivoFiltradas: [],
    },
    getters: {
        listPreventivo: state => state.listPreventivo,
        listPreventivoFiltradas: state => state.listPreventivoFiltradas,
    },
    mutations: {
        SET_LISTPREVENTIVO(state, listPreventivo) {
            state.listPreventivo = listPreventivo
        },
        SET_LISTPREVENTIVOFILTRADAS(state, listPreventivoFiltradas) {
            state.listPreventivoFiltradas = listPreventivoFiltradas
        },
    },
    actions: {
        async GET_LISTAPREVENTIVO({ commit }) {
            await axios.get("lista_preventivo").then(response => {
                commit('SET_LISTPREVENTIVO', response.data)
            }).catch(error => {
                commit('SET_LISTPREVENTIVO', [])
            })
        },
        async GET_LISTAPREVENTIVOFILTRADAS({ commit },value) {
            await axios.get('lista_preventivo_filtrada', {
                params: {
                    codigo: value[0],
                    nombre: value[1],
                    tipo: value[2],
                    opcion: value[3],
                    equipo: value[4],
                    standar: value[5],
                    estado: value[6],
                }
            }).then((response) => {
                const preventivo = response.data;
                commit('SET_LISTPREVENTIVOFILTRADAS', preventivo)
            }) .catch(error => {
                console.error(error)
                commit('SET_LISTPREVENTIVOFILTRADAS', [])
            })
        },
    },
    modules: {
    }
}