
'use strict'
import axios from 'axios'
import { api, files } from '../utils/utilidades'
axios.defaults.baseURL = api;
export default {
    namespaced: true,
    state: {
        listaCriticidad: [],
        listaEstados: [],
        listaTipoEquipo: [],
        listaSubtipoEquipo: [],

    },
    getters: {
        listaCriticidad: state => state.listaCriticidad,
        listaEstados: state => state.listaEstados,
        listaTipoEquipo: state => state.listaTipoEquipo,
        listaSubtipoEquipo: state => state.listaSubtipoEquipo,
    },
    mutations: {
        SET_LISTACRITICIDAD(state, listaCriticidad) {
            state.listaCriticidad = listaCriticidad
        },
        SET_LISTAESTADOS(state, listaEstados) {
            state.listaEstados = listaEstados
        },
        SET_LISTATIPOEQUIPO(state, listaTipoEquipo) {
            state.listaTipoEquipo = listaTipoEquipo
        },
        SET_LISTASUBTIPOEQUIPO(state, listaSubtipoEquipo) {
            state.listaSubtipoEquipo = listaSubtipoEquipo
        },

    },
    actions: {
        async GET_LISTACRITICIDAD({ commit }) {
            await axios.get('obtener_parametro_order_id', {
                params: {
                    tipo: 13,
                    estado: 1,
                }
            }).then(response => {
                const items = response.data;
                commit('SET_LISTACRITICIDAD', items)
            }).catch(error => {
                console.log(error);
                commit('SET_LISTACRITICIDAD', [])
            });
        },
        async GET_LISTAESTADOS({ commit }) {
            await axios.get('obtener_parametro_order_id', {
                params: {
                    tipo: 1,
                    estado: 1,
                }
            }).then(response => {
                const items = response.data;
                commit('SET_LISTAESTADOS', items)
            }).catch(error => {
                console.log(error);
                commit('SET_LISTAESTADOS', [])
            });
        },
        async GET_LISTATIPOEQUIPO({ commit }) {
            await axios.get('obtener_parametro_order_id', {
                params: {
                    tipo: 11,
                    estado: 1,
                }
            }).
                then(response => {
                    const items = response.data;
                    commit('SET_LISTATIPOEQUIPO', items)
                }).catch(error => {
                    console.log(error);
                    commit('SET_LISTATIPOEQUIPO', [])
                });
        },
        async GET_LISTASUBTIPOEQUIPO({ commit }) {
            await axios.get('obtener_parametro_order_id', {
                params: {
                    tipo: 12,
                    estado: 1,
                }
            }).
                then(response => {
                    const items = response.data;
                    commit('SET_LISTASUBTIPOEQUIPO', items)
                }).catch(error => {
                    console.log(error);
                    commit('SET_LISTASUBTIPOEQUIPO', [])
                });
        },
    },
    modules: {
    }
}

