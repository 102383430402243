
'use strict'
import axios from 'axios'
import { api, files } from '../utils/utilidades'
axios.defaults.baseURL = api;
export default {
    namespaced: true,
    state: {
        listaStandarJob: [],
        listaStandarJobFiltrada: [],
        tabCabeceraStandarJob: false,
        tabOperacionesStandarJob: true,
        tabSuboperacionesStandarJob: true,
        tabSigStandarJob: true,
        tabMaterialesStandarJob: true,
        tabServiciosStandarJob: true,
        selectTabStandarJob: 0,

        standar_job: [],
        listaOperacionesStandarJob: [],
        listaSubOperacionesStandarJob: [],
        listaControlesSigStandarJob: [],
        listaMaterialesStandarJob: [],
        listaServiciosStandarJob: [],
        editOperacionStandarJob: false,
        editarOperacionStandarJob: false,
        editarSuboperacionStandarJob: false,
        editarControlStandarJob: false,
        editarMaterialStandarJob: false,
        editarServicioStandarJob: false,
        editMaterialStandarJob: [],
        editServicioStandarJob: [],
        standarJobObtenida: null,
        numeroStandarJob: null,
        resetearStandarJob: false,
        nuevoStandarJob: false,

    },
    getters: {
        listaStandarJob: state => state.listaStandarJob,
        listaStandarJobFiltrada: state => state.listaStandarJobFiltrada,
        tabCabeceraStandarJob: state => state.tabCabeceraStandarJob,
        tabOperacionesStandarJob: state => state.tabOperacionesStandarJob,
        tabSuboperacionesStandarJob: state => state.tabSuboperacionesStandarJob,
        tabSigStandarJob: state => state.tabSigStandarJob,
        tabMaterialesStandarJob: state => state.tabMaterialesStandarJob,
        tabServiciosStandarJob: state => state.tabServiciosStandarJob,
        selectTabStandarJob: state => state.selectTabStandarJob,


        standar_job: state => state.standar_job,
        listaOperacionesStandarJob: state => state.listaOperacionesStandarJob,
        listaSubOperacionesStandarJob: state => state.listaSubOperacionesStandarJob,
        listaControlesSigStandarJob: state => state.listaControlesSigStandarJob,
        listaMaterialesStandarJob: state => state.listaMaterialesStandarJob,
        listaServiciosStandarJob: state => state.listaServiciosStandarJob,
        editOperacionStandarJob: state => state.editOperacionStandarJob,
        editarOperacionStandarJob: state => state.editarOperacionStandarJob,
        editarSuboperacionStandarJob: state => state.editarSuboperacionStandarJob,
        editarControlStandarJob: state => state.editarControlStandarJob,
        editarMaterialStandarJob: state => state.editarMaterialStandarJob,
        editarServicioStandarJob: state => state.editarServicioStandarJob,
        editMaterialStandarJob: state => state.editMaterialStandarJob,
        editServicioStandarJob: state => state.editServicioStandarJob,
        standarJobObtenida: state => state.standarJobObtenida,
        numeroStandarJob: state => state.numeroStandarJob,
        resetearStandarJob: state => state.resetearStandarJob,
        nuevoStandarJob: state => state.nuevoStandarJob,
    },
    mutations: {
        SET_LISTASTANDARES(state, listaStandarJob) {
            state.listaStandarJob = listaStandarJob
        },
        SET_LISTASTANDARESFILTRADAS(state, listaStandarJobFiltrada) {
            state.listaStandarJobFiltrada = listaStandarJobFiltrada
        },
        SET_TABCABECERA(state, tabCabeceraStandarJob) {
            state.tabCabeceraStandarJob = tabCabeceraStandarJob
        },
        SET_TABOPERACIONES(state, tabOperacionesStandarJob) {
            state.tabOperacionesStandarJob = tabOperacionesStandarJob
        },
        SET_TABSUBOPERACIONES(state, tabSuboperacionesStandarJob) {
            state.tabSuboperacionesStandarJob = tabSuboperacionesStandarJob
        },
        SET_TABSIG(state, tabSigStandarJob) {
            state.tabSigStandarJob = tabSigStandarJob
        },
        SET_TABMATERIALES(state, tabMaterialesStandarJob) {
            state.tabMaterialesStandarJob = tabMaterialesStandarJob
        },
        SET_TABSERVICIOS(state, tabServiciosStandarJob) {
            state.tabServiciosStandarJob = tabServiciosStandarJob
        },
        SET_SELECTTAB(state, selectTabStandarJob) {
            state.selectTabStandarJob = selectTabStandarJob
        },

        SET_STANDARJOB(state, standar_job) {
            state.standar_job = standar_job
        },
        SET_LISTAOPERACIONES(state, listaOperacionesStandarJob) {
            state.listaOperacionesStandarJob = listaOperacionesStandarJob
        },
        SET_LISTASUBOPERACIONES(state, listaSubOperacionesStandarJob) {
            state.listaSubOperacionesStandarJob = listaSubOperacionesStandarJob
        },
        SET_LISTACONTROLESSIG(state, listaControlesSigStandarJob) {
            state.listaControlesSigStandarJob = listaControlesSigStandarJob
        },
        SET_LISTAMATERIALES_STANDARJOB(state, listaMaterialesStandarJob) {
            state.listaMaterialesStandarJob = listaMaterialesStandarJob
        },
        SET_LISTASERVICIOS_STANDARJOB(state, listaServiciosStandarJob) {
            state.listaServiciosStandarJob = listaServiciosStandarJob
        },
        SET_EDITOPERACION(state, editOperacionStandarJob) {
            state.editOperacionStandarJob = editOperacionStandarJob
        },
        SET_EDITAROPERACION(state, editarOperacionStandarJob) {
            state.editarOperacionStandarJob = editarOperacionStandarJob
        },
        SET_EDITARSUBOPERACION(state, editarSuboperacionStandarJob) {
            state.editarSuboperacionStandarJob = editarSuboperacionStandarJob
        },
        SET_EDITARCONTROL(state, editarControlStandarJob) {
            state.editarControlStandarJob = editarControlStandarJob
        },
        SET_EDITARMATERIAL(state, editarMaterialStandarJob) {
            state.editarMaterialStandarJob = editarMaterialStandarJob
        },
        SET_EDITARSERVICIO(state, editarServicioStandarJob) {
            state.editarServicioStandarJob = editarServicioStandarJob
        },

        SET_EDITMATERIAL(state, editMaterialStandarJob) {
            state.editMaterialStandarJob = editMaterialStandarJob
        },
        SET_EDITSERVICIO(state, editServicioStandarJob) {
            state.editServicioStandarJob = editServicioStandarJob
        },
        SET_STANDARJOBOBTENIDA(state, standarJobObtenida) {
            state.standarJobObtenida = standarJobObtenida
        },
        SET_NUMEROSTANDARJOB(state, numeroStandarJob) {
            state.numeroStandarJob = numeroStandarJob
        },
        SET_RESETEARSTANDARJOB(state, resetearStandarJob) {
            state.resetearStandarJob = resetearStandarJob
        },
        SET_NUEVOSTANDARJOB(state, nuevoStandarJob) {
            state.nuevoStandarJob = nuevoStandarJob;
        },
    },
    actions: {
        async GET_LISTASTANDARES({ commit }) {
            await axios.get('lista_standares_job').then((response) => {
                const standares = response.data;
                commit('SET_LISTASTANDARES', standares)
            }) .catch(error => {
                console.error(error)
                commit('SET_LISTASTANDARES', [])
            })
        },
        async GET_LISTASTANDARESFILTRADAS({ commit }, value) {
            await axios.get('lista_standares_job_filtrada', {
                params: {
                    numero: value[0],
                    descripcion: value[1],
                    tipo: value[2],
                    disciplina: value[3],
                    equipo: value[4],
                    estado: value[5],
                }
            }).then((response) => {
                const standares = response.data;
                commit('SET_LISTASTANDARESFILTRADAS', standares)
            }) .catch(error => {
                console.error(error)
                commit('SET_LISTASTANDARESFILTRADAS', [])
            })
        },
        SELECT_TABCABECERA({ commit }, estado) {
            commit('SET_TABCABECERA', estado)
        },
        SELECT_TABOPERACIONES({ commit }, estado) {
            commit('SET_TABOPERACIONES', estado)
        },
        SELECT_TABSUBOPERACIONES({ commit }, estado) {
            commit('SET_TABSUBOPERACIONES', estado)
        },
        SELECT_TABSIG({ commit }, estado) {
            commit('SET_TABSIG', estado)
        },
        SELECT_TABMATERIALES({ commit }, estado) {
            commit('SET_TABMATERIALES', estado)
        },
        SELECT_TABSERVICIOS({ commit }, estado) {
            commit('SET_TABSERVICIOS', estado)
        },
        SELECT_TAB({ commit }, estado) {
            commit('SET_SELECTTAB', estado)
        },

        SETEAR_STANDARJOB({ commit }, standar_job) {
            commit('SET_STANDARJOB', standar_job)
        },
        SETEAR_LISTAOPERACIONES({ commit }, listaOperaciones) {
            commit('SET_LISTAOPERACIONES', listaOperaciones)
        },
        SETEAR_LISTASUBOPERACIONES({ commit }, listaSubOperaciones) {
            commit('SET_LISTASUBOPERACIONES', listaSubOperaciones)
        },
        SETEAR_LISTACONTROLESSIG({ commit }, listaControlesSig) {
            commit('SET_LISTACONTROLESSIG', listaControlesSig)
        },
        SETEAR_LISTAMATERIALES_STANDARJOB({ commit }, listaMateriales) {
            commit('SET_LISTAMATERIALES_STANDARJOB', listaMateriales)
        },
        SETEAR_LISTASERVICIOS_STANDARJOB({ commit }, listaServicios) {
            commit('SET_LISTASERVICIOS_STANDARJOB', listaServicios)
        },

        ADD_NEWOPERACION({ commit }, orden) {
            const ordenTrabajoOperacion = {
                idOrdenTrabajo: orden.id,
                descripcion: orden.descripcion,
                idDepartamento: { id: 1 },
                id014EstadoOrdenTrabajoOperacion: 1,
                numero: 1,
                fechaInicioProgramada: orden.fechaInicioProgramada,
                horaInicioProgramada: orden.horaInicioProgramada,
                duracionProgramada: orden.duracionProgramada,
                fechaFinProgramada: orden.fechaFinProgramada,
                horaFinProgramada: orden.horaFinProgramada,
                fechaInicioReal: null,
                horaInicioReal: null,
                duracionReal: null,
                fechaFinReal: null,
                horaFinReal: null,
            };
            const listOp = [];
            listOp.push(ordenTrabajoOperacion);
            var idOper = 0;
            axios.post('agregarOrdenTrabajoOperacion', listOp)
                .then(response => {
                    idOper = response.data.idsOperacion[0];
                    const listOrdenTrabajoRecurso = [];
                    const OrdenTrabajoOperacionRecurso = {
                        id: null,
                        idOrdenTrabajoOperacion: { id: idOper },
                        idRecurso: { id: 1 },
                        cantidadProgramado: 1,
                        cantidadReal: 0,
                        horasHombreProgramado: 0,
                        horasHombreReal: 0,
                        id001Estado: 1,
                    }
                    listOrdenTrabajoRecurso.push(OrdenTrabajoOperacionRecurso);

                    axios.post('guardarOrdenTrabajoRecurso', listOrdenTrabajoRecurso)
                        .then(response => {
                            var nro = 1;
                            const operacion = [{
                                id: response.data.recursos[0].id,
                                idOp: idOper,
                                des: orden.descripcion,
                                nro: nro,
                                sec: 1,
                                dur: orden.duracionProgramada,
                                recurso: null,
                                can: 1,
                                instaNomb: false,
                                dep: orden.idDepartamento,
                                ini: orden.fechaInicioProgramada,
                                hini: orden.horaInicioProgramada,
                                fin: orden.fechaFinProgramada + ' ' + orden.horaFinProgramada,
                                idOpRecurso: response.data.idsRecursos[0],
                                rec: [],
                                recNomb: '',
                                adjuntos: [],
                                flagRecurso: false,
                                hh: 0,
                            }];
                            commit('SET_LISTAOPERACIONES', operacion);
                            commit('SET_EDITOPERACION', true);

                        })
                })
        },
        SETEAR_EDITOPERACION({ commit }, item) {
            commit('SET_EDITOPERACION', item);
        },
        SETEAR_EDITAROPERACION({ commit }, item) {
            commit('SET_EDITAROPERACION', item);
        },
        SETEAR_EDITARSUBOPERACION({ commit }, item) {
            commit('SET_EDITARSUBOPERACION', item);
        },
        SETEAR_EDITARCONTROL({ commit }, item) {
            commit('SET_EDITARCONTROL', item);
        },
        SETEAR_EDITARMATERIAL({ commit }, item) {
            commit('SET_EDITARMATERIAL', item);
        },
        SETEAR_EDITARSERVICIO({ commit }, item) {
            commit('SET_EDITARSERVICIO', item);
        },
        SETEAR_EDITMATERIAL({ commit }, item) {
            commit('SET_EDITMATERIAL', item);
        },
        SETEAR_EDITMATERIAL({ commit }, item) {
            commit('SET_EDITSERVICIO', item);
        },
        SETEAR_STANDARJOBOBTENIDA({ commit }, item) {
            commit('SET_STANDARJOBOBTENIDA', item);
        },
        SETEAR_NUMEROSTANDARJOB({ commit }, item) {
            commit('SET_NUMEROSTANDARJOB', item);
        },
        SETEAR_RESETEARSTANDARJOB({ commit }, item) {
            commit('SET_RESETEARSTANDARJOB', item);
        },
        SETEAR_NUEVOSTANDARJOB({ commit }, item) {
            commit('SET_NUEVOSTANDARJOB', item)
        },

    },
    modules: {
    }
}

