<template style="overflow-y: auto;">
  <v-app id="inspire">
    <v-app-bar v-if="$route.path != '/login'" app color="primaryCallao" dark absolute
      scroll-target="#scrolling-techniques">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" title="Menú de Opciones"></v-app-bar-nav-icon>

      <v-toolbar-title> <v-img src="./assets/logo.png" width="130px"></v-img>

      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-title style="font-size:x-large">{{ pageTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip v-if="$route.path === '/ordenes'" bottom>
        <template #activator="{ on }">
          <v-btn v-if="grilla" v-on="on" icon @click="abrirGrilla(true)">
            <v-icon>mdi-table-eye</v-icon>
          </v-btn>
          <v-btn v-else v-on="on" icon @click="abrirGrilla(false)">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </template><span>{{ grilla ? 'Ver en formato de grilla' : 'Regresar' }}</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute color="secundaryCallao" temporary :width="350">
      <v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-title class="tile" style="font-weight: 700; font-size: 1rem;  white-space: normal;">
            {{ user != null ? user.idPersona.nombres + ' ' + user.idPersona.apellidoPaterno + ' ' +
              user.idPersona.apellidoMaterno : '' }}</v-list-item-title>
          <v-list-item-title>
            <v-list-item-avatar size="100" style="margin:0">
              <v-img :src="imagenUser != null ? imagenUser : 'https://randomuser.me/api/portraits/men/78.jpg'"
                class="mx-auto"></v-img>
            </v-list-item-avatar>
          </v-list-item-title>
          <dialogCargaImagen></dialogCargaImagen>
          <v-list-item-title class="tile" style="font-size: 0.85rem;">
            <template v-if="roles != null && roles.length > 0">
              <span v-for="(role, index) in roles" :key="index">
                {{ role.idRol.nombre }}<span v-if="index < roles.length - 1"> </span><br>
              </span>
            </template>
            <template v-else>
              <!-- Mensaje a mostrar cuando roles es nulo o está vacío -->
              SIN PERFIL
            </template>
          </v-list-item-title>
          <v-list-item-title class="amarilloCallao--text">{{ user != null ? (user.email != null ? user.email : '') : '' }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-divider></v-divider>

      <v-list dense>
        <v-list-group active-class="amarilloCallao--text" active-style="background-color: amarilloCallao;"
          v-for="(item, index) in menu" :key="index" :value="item.active" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon class="tile white--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="tile white--text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item active-class="amarilloCallao--text" v-for="(child, childIndex) in item.children" :key="childIndex"
            :to="child.url" link @click="ocultarMenu">
            <v-list-item-icon>
              <v-icon class="tile amarilloCallao--text">{{ child.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="tile white--text">{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item @click="cambioClave" style="cursor: pointer; ">
          <v-list-item-icon>
            <v-icon class="tile white--text">mdi-lock-reset</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile white--text">CAMBIO DE CLAVE</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="cerrarSesion" style="cursor: pointer; ">
          <v-list-item-icon>
            <v-icon class="tile white--text">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile white--text">CERRAR SESIÓN</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main style="overflow-y: auto; height: 100vh;">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
    </v-main>
    <dialogCambioClave v-model="isChangeKeyDialogOpen" @input="isChangeKeyDialogOpen = $event" />
  </v-app>
</template>

<script>
import axios from 'axios';
import { api, files } from '@/utils/utilidades';
axios.defaults.baseURL = api;
import dialogCargaImagen from "@/components/Utils/dialogs/dialogUploadImagen.vue"
import dialogCambioClave from "@/components/Utils/dialogs/dialogChangeKey.vue"
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
const {
  mapGetters: usuarioMapGetter,
  mapActions: usuarioMapActions
} = createNamespacedHelpers('usuario')

export default {
  name: 'App',
  computed: {
    pageTitle() {
      // Accede a la ruta activa y recupera la propiedad `title` del componente de la vista actual
      return this.$route.meta.title;
    },
    ...usuarioMapGetter([
      'isLoggedIn',
      'menu',
      'user',
      'roles',
      'imagenUser',
      'idUser'
    ]
    ),
  },
  data: () => ({
    drawer: false,
    group: null,
    drawer: null,
    image: null,
    grilla: true,
    isChangeKeyDialogOpen: false,
  }),
  components: {
    dialogCargaImagen,
    dialogCambioClave
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  mounted() {
  },
  created() {
    if (this.$session.exists()) {
      this.SETEAR_USER(this.$session.get('userData'));
      this.SETEAR_MENU(this.$session.get('userMenu'));
      this.SETEAR_ROLES(this.$session.get('usuarioRoles'));
      this.SETEAR_IMAGENUSUARIO(this.$session.get('imagenUsuario'));
      this.SETEAR_ESTADOSSOLICITUDES(this.$session.get('estadosSolicitudes'));
      this.SETEAR_ESTADOSXROLES(this.$session.get('estadosOrden'));
      this.SETEAR_IDUSER(this.$session.get('idUser'));

      this.$store.dispatch('ordenstore/GET_ITEMSCATEGORIACONTROLESSIG');
      this.$store.dispatch('ordenstore/GET_ITEMSCATEGORIASUBOPERACIONES');
      this.$store.dispatch('ordenstore/GET_ITEMSTIPOCONTROLESSIG');
      this.$store.dispatch('ordenstore/GET_ITEMSTIPOSUBOPERACIONES');

    } else {
      this.$router.push('/login');
    }
  },
  methods: {
    ...usuarioMapActions([
      'login',
      'SETEAR_USER',
      'SETEAR_MENU',
      'SETEAR_ROLES',
      'SETEAR_ESTADOSXROLES',
      'SETEAR_ESTADOSSOLICITUDES',
      'SETEAR_IMAGENUSUARIO',
      'SETEAR_IDUSER'
    ]),
    abrirGrilla(value) {
      if (this.$route.path === '/ordenes') {
        this.$root.$emit('filtro-ordenes', value);
        this.grilla = !value;
      }
    },
    cerrarSesion() {
      const fecha = new Date();
      const year = fecha.getFullYear();
      const month = String(fecha.getMonth() + 1).padStart(2, '0');
      const day = String(fecha.getDate()).padStart(2, '0');

      const horas = String(fecha.getHours()).padStart(2, '0');
      const minutos = String(fecha.getMinutes()).padStart(2, '0');
      const segundos = String(fecha.getSeconds()).padStart(2, '0');

      const horaActual = `${horas}:${minutos}:${segundos}`;
      const fechaActual = `${year}-${month}-${day}`;

      const log = {
        accion: "CERRAR_SESION",
        idUsuario: this.idUser,
        fecha: fechaActual,
        hora: horaActual,
      }
      axios
        .post('cerrar_sesion', log)
        .then((response) => {
        })
        .catch((err) => {
          console.log('err', err)
        });

      this.ocultarMenu();
      this.$session.destroy()
      this.SETEAR_USER(null);
      this.$router.push('/login');
      this.SETEAR_MENU(null);
      this.SETEAR_ROLES(null);
      this.SETEAR_ESTADOSXROLES(null);
      this.SETEAR_ESTADOSSOLICITUDES(null);
    },
    cambioClave() {
      this.isChangeKeyDialogOpen = true;
    },
    ocultarMenu() {
      this.drawer = false;
    }
  }
};
</script>

<style>
html {
  overflow: hidden !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #7c5b47;
  border-radius: 5px;
}

.v-input--is-disabled ::v-deep input {
  color: #555555;
}

.v-text-field--outlined.v-input--dense ::v-deep .v-label--is-disabled {
  color: #555555;
}
</style>

<style scoped>
.tile {
  color: white
}

.v-list-item:hover {
  background-color: #775844;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-main {
  height: calc(100% - 64px);
  overflow-y: auto;
}
</style>

